/* You can add global styles to this file, and also import other style files */
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on December 20, 2019 */
/// @group random
$selected-bg: #041e42;
$primary: #041e42;
$accent: #041e42 !default;
$kendo-color-primary: #041e42;
@import '@progress/kendo-theme-default/dist/all.scss';
@import "ngx-smart-modal/ngx-smart-modal";
@font-face {
    font-family: 'nunito_sansblack';
    src: url('assets/fonts/nunitosans-black-webfont.woff2') format('woff2'),
         url('assets/fonts/nunitosans-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'nunito_sansblack_italic';
    src: url('assets/fonts/nunitosans-blackitalic-webfont.woff2') format('woff2'),
         url('assets/fonts/nunitosans-blackitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'nunito_sansbold';
    src: url('assets/fonts/nunitosans-bold-webfont.woff2') format('woff2'),
         url('assets/fonts/nunitosans-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'nunito_sansbold_italic';
    src: url('assets/fonts/nunitosans-bolditalic-webfont.woff2') format('woff2'),
         url('assets/fonts/nunitosans-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'nunito_sansextralight';
    src: url('assets/fonts/nunitosans-extralight-webfont.woff2') format('woff2'),
         url('assets/fonts/nunitosans-extralight-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'nunito_sansextralight_italic';
    src: url('assets/fonts/nunitosans-extralightitalic-webfont.woff2') format('woff2'),
         url('assets/fonts/nunitosans-extralightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'nunito_sansitalic';
    src: url('assets/fonts/nunitosans-italic-webfont.woff2') format('woff2'),
         url('assets/fonts/nunitosans-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'nunito_sanslight';
    src: url('assets/fonts/nunitosans-light-webfont.woff2') format('woff2'),
         url('assets/fonts/nunitosans-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'nunito_sanslight_italic';
    src: url('assets/fonts/nunitosans-lightitalic-webfont.woff2') format('woff2'),
         url('assets/fonts/nunitosans-lightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'nunito_sansregular';
    src: url('assets/fonts/nunitosans-regular-webfont.woff2') format('woff2'),
         url('assets/fonts/nunitosans-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'nunito_sanssemibold';
    src: url('assets/fonts/nunitosans-semibold-webfont.woff2') format('woff2'),
         url('assets/fonts/nunitosans-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'nunito_sanssemibold_italic';
    src: url('assets/fonts/nunitosans-semibolditalic-webfont.woff2') format('woff2'),
         url('assets/fonts/nunitosans-semibolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

html{
    overflow: hidden;
}

body{
    font-family: 'nunito_sansregular' !important;
    /* font-size: 14px !important; */
}

.mbtn-outline-primary{
    border:1px solid #041e42 !important;
    color: #041e42 !important;
}
.mbtn-outline-primary:hover,  .mbtn-outline-primary:active,  .mbtn-outline-primary.active{
    background:  #041e42 !important;
    color:#fff !important;
}
.btn-primary{
    background: #041e42 !important;
    color:#fff !important;
}

.btn-secondary{
    background: #cba052 !important;
    color:#fff !important;
}
.btn-secondary:hover,  .btn-secondary:active,  .btn-secondary.active{
    background: #c79944 !important;
}


.main-tab ul.nav.nav-tabs .nav-link:active, .main-tab ul.nav.nav-tabs .nav-link.active {
    margin-left: -1px;
    margin-top: 0px;
    color: #041e42;
    border: none !important;
    border-bottom: 2px solid #041e42 !important;
}
 /* .main-tab ul.nav.nav-tabs .nav-link.active::before {
  content: "";
  position: absolute;
  border-left: 7px solid #041e42;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-right: 7px solid transparent;
}  */
.btn-file {
    position: relative;
    overflow: hidden;
  }
  .btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    background: red;
    cursor: inherit;
    display: block;
    left: 0;
  }
  .file-input-label {
    padding: 0px 10px;
    display: table-cell;
    vertical-align: middle;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 300px;
    height: 38px;
    margin-left: -66px;
    margin-top: -3px;
  }
  .btn.mbtn-upload {
    background: #6c757d;
    color: #fff;
    border-top-right-radius: 0px;
border-bottom-right-radius: 0px;
}
.mbtn-upload:hover, .mbtn-upload:active, .mbtn-upload.active{
    color: #fff !important;
    background-color: #6c757d;
   
}
.cus-accordion .card-header {
    padding: 0px !important;
}
.btn-outline-success, .btn-outline-danger {
    padding: 4px 8px !important;
}
span.help-block {
    color: #ca0808;
}
/* .main-tab .tab-content {
    border: 1px solid #fff;
    box-shadow: 0px 0px 4px #ccc;
    padding: 10px;
} */
.main-tab .tab-content {
    border: none;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}


.btn .btn-ur-ttip{
    border:none !important;
    background: none !important;
}
.tooltip-inner {
   max-width: 400px !important;
   padding: .5rem 1rem !important;
   white-space: pre-line;
}
button.btn.btn-ur-ttip {
    padding: 0px;
}
 
.side-header input[type="checkbox"]{
   color: red !important;
}
.side-header input[type="checkbox"]:checked{
    color:green  !important;
 }
 .side-header input[type="checkbox"]:checked ~ app-component-data .main-wrapper{
    padding: 200px !important;
 }

input[type="checkbox"]:checked  ~ app-component-data .main-wrapper {
   background: #000;
   width: 100px;
   height: 100px;
   padding: 10em;
}

.wrapper-div {
    width: calc(100% - 230px);
    height: calc(100% - 98px);
    margin-left: 224px;
    margin-top: 54px;
    transition: .2s ease-out;
    background-color: #333;
}

.wrapper-div-unchecked {
    width: calc(100% - 74px);
    height: calc(100% - 98px);
    margin-left: 68px;
    margin-top: 54px;
    transition: .1s ease-out;
    background-color: #333;
}


.main-div{
    background: #fff;
    height: calc(100vh - 98px);
}


.main-wrapper {
    margin-top: 4px;
    margin-left: auto;
    position: relative;   
    transition: transform 250ms ease-in-out;
    height: calc(100% - 98px);
}

input[type="checkbox"]:checked ~ .main-wrapper {
    width: calc(100% - 220px) !important;
    margin-left: auto !important;
    margin-bottom: 15px;
    height: calc(100% - 98px);
}


.navbar-brand {
    margin-left: 5rem !important;
  
}
/* .main-tab ul.nav-tabs {
    border-bottom: none !important;
} */
::ng-deep .main-tab ul.nav.nav-tabs .nav-link {
    color: #333;
    font-weight: 500;
    border: none !important;
}
::ng-deep .main-tab ul.nav-tabs .nav-link{
    border: none !important;
    border-radius: none !important;
}
.main-tab ul.nav-tabs li.nav-item a {
    color: #041e42 !important;
    font-weight: 500;
}
.main-tab ul.nav-tabs li.nav-item a.active, .main-tab ul.nav-tabs li.nav-item a:active, .main-tab ul.nav-tabs li.nav-item a:hover {
    color: #041e42 !important;
    font-weight: bold;
}
.main-tab ul.nav.nav-tabs {
    border: 1px solid rgba(0,0,0,.03);
    box-shadow: 0 2px 2px rgba(0,0,0,.24), 0 0 2px rgba(0,0,0,.12);
}
.cus-accordion button.btn.btn-link {
    width: 100%;
    text-align: left;
    color: #ffffff;
    background: rgba(4, 30, 66, 0.5607843137254902);
}
.fixed-scrup .custom-file {
   
    position: fixed !important;
    width: 15% !important;
    margin-left: 39%;
}

.cus-tabby {
    border: 1px solid #eee;
    padding: 20px 10px 10px 5px !important;
    margin-top: 0px;
}
.footer{

    background: #041e42;
    height: 40px;
    text-align: center;
    color: #fff;
        z-index: 999;
    border-top: 1px solid #fff;
}
body {
   
    background-color: lightgray !important;
}
.srdo-scdata ul{
 padding: 0px;
}
.srdo-scdata ul li{
    list-style-type: none;
    padding: 10px 0px 10px 10px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
   }
   .srdo-scdata ul li:hover, .srdo-scdata ul li:active{
  background: #041e42;
  color: #fff;
   }
   /* .sd-fixeddt {
    position: fixed; */
    /* z-index: 9; 
    /* width: 20%; 
    min-height: 400px;
    max-height: 580px;
    overflow: auto;
}*/
.panel-table tr th {
    width: 25%;
}
.cus-accordion {
    border: 2px solid #f4f4f4;
    border-radius: 0px;
}
.btn-outline-primary {
    color: #041e42 !important;
    border-color: #041e42 !important;
}
.btn-outline-primary:hover, .btn-outline-primary:active, .btn-outline-primary.active {
  background: #041e42 !important;
  color:#fff !important;
}
.custom-file-label::after {
  
    color: #041e42 !important;
    background-color: #ffffff !important;
    border: 1px solid #041e42 !important;
    cursor: pointer;
}
.custom-file-label::after:hover {
  
   
    background-color: #041e42 !important;
  
}
.custom-file-label {
  
    color: #041e42 !important;
    background-color: #fff;
    border: 1px solid #041e42 !important;
   
}
.invalid-feedback {
    font-size: 90% !important;
    color: #dc3545;
    margin-top: 1px !important;
    width: auto !important;
    display: inline-flex !important;
    font-weight: bold;
    margin-left: 7px;
    transition: .3s all;
}
.bg-success .toast-header {
   
    color: #ffffff !important;
    background-color: rgb(0, 100, 0) !important;
   
    border-bottom: 1px solid rgba(0,0,0,.05);
}
.toast.show.bg-success p {
    margin-bottom: 0px !important;
    color: darkgreen !important;
  }
  .bg-success .toast-header button.close, .bg-danger .toast-header button.close {
    color: #fff;
}
  .toast.show.bg-success{
    background:rgba(255,255,255,.85) !important;
    position: fixed;
    top: 21%;
    right: 3%;
   width: 100%;
  }

  .bg-danger .toast-header {
   
    color: #ffffff !important;
    background-color: #bd2130 !important;
   
    border-bottom: 1px solid rgba(0,0,0,.05);
}
.toast.show.bg-danger p {
    margin-bottom: 0px !important;
    color: #bd2130 !important;
  }

  .toast.show.bg-success, .toast.show.bg-danger{
    background: rgba(255,255,255,.85) !important;
    position: fixed;
    top: 21%;
    right: 3%;
    width: 100%;
    height: calc(100vh - 33.5rem);
    overflow-y: scroll;
  }
  
  .srdo-leftdiv {
    width: 26%;
    float: left;
    margin-right: 15px;
    height: calc(100vh - 320px);
    overflow: auto;
    border-top: 1px solid #ccc;
    box-shadow:  0px 2px 2px rgba(195, 167, 167, 0.63);
}
.srdo-rightdiv {
    width: 72%;
    float: left;
}

/***cookies*****/
.cookies-div{
    position: fixed;
    bottom: 50px;
    background: rgba(0, 37, 88, 0.5686274509803921);
    color: #fff;
    text-align: center;
    padding: 20px 50px;
    font-size: 18px;
    width: 100%;
    left: 0
}


/***cookies*****/
span.danger {
    position: absolute;
    z-index: auto;
    color: #bd2130 !important;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    top: -52px;
    right: 15px;
}
 
.fixed-divscr {
    height: calc(100vh - 320px);
    overflow: auto;
}
 .fixed-sc {
    position: fixed;
    z-index: 9999;
    width: 19.7%;
}
.fixed-sc .input-sc {
    width: 55%;
    float: left;
}
.search-sc {
    width: 42%;
    float: left;
    margin-left: 2%;
}
/* .srdo-scdata {
    position: absolute !important;
    top: 50px;
    border: 1px solid #ccc;
}   */
.cus-lrdiv{
    margin-top: 4%;;
}
.fixed-scrup {
    width: 70%;
    float: left;
    position: fixed;
}
.fixed-scrup .input-group {
    width: 15%;
    float: left;
}
.fixed-scrup .cs-search {
    width: 13%;
    float: left;
    margin-left: 1%;
}
.navbar {
  
    padding: 0px !important;
}
.navbar-nav {

    width: 100%;
}

@media (max-width: 1400px){
    body{
        font-family: 'nunito_sansregular' !important;
         font-size: 13px !important; 
    }
     .btn, button, input, optgroup, select, textarea{
        font-size: 14px !important;
    } 

    ::ng-deep .nav-tabs .nav-item  {
        margin-bottom: -1px;
        text-align: center;
    } 
    .sidebarMenuInner li{
        padding: 7px 10px !important;
      
    }
    #sidebarMenu{
        width: 220px !important;
    }

    input[type="checkbox"]:checked .sidebarMenu {
        width: 0px !important;
    }

    .sidebarIconToggle {
      
        left: 10px !important;
      
    }
   
    span.danget {
        position: fixed;
        right:50px;
        top: 28%;
        z-index:auto;
        color:#bd2130;
        font-size:14px;
        font-weight:500;
    }
    .srdo-scdata ul li {
      
        padding: 5px 0px 5px 10px !important;
        font-size: 12px !important;
    }
}
.k-grid {
    font-size: 13px !important;
    font-family: 'nunito_sansregular' !important;
}

.button-notification {
    padding: 10px 5px;
    margin-top: 60px;
    max-width:700px !important;
    max-height: 700px;
    flex-grow: 1;
    min-height: 0;
    overflow: auto;
}

.k-dialog-titlebar {
    color: #fff;
    background-color: #041e42;
}

.title-color {
    background-color: #041e42;
}

button, input, select { 
    outline: 0 !important;
    box-shadow: none !important;
  }

  .k-listview-header {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
}

.k-notification.k-notification-info {
    background-color: #041e42;
    border-color: #041e42;
}

// .btn.btn-link {
//     color: #041e42 !important;
//     font-weight: bold !important;
// }
